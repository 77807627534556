import React from 'react';
import styled, { css } from 'styled-components';

const Message = styled.div`
	width: 100%;
	background: ${p => p.theme.colors.white};
	border-radius: ${p => p.theme.utils.borderRadius};
	overflow: hidden;
	padding: 20px;
	position: relative;
	box-sizing: border-box;
	color: ${p => p.theme.colors.grey900};
	margin-top: 30px;
	border: 1px solid ${p => p.theme.colors.grey300};
	border-left: 5px solid
		${p =>
			p.color === 'green'
				? p.theme.colors.green500
				: p.theme.colors.coral300};
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 20px;
		`)}
`;

export default function NoErrors({ text = 'Ingen feilmeldinger', ...props }) {
	return (
		<Message color="green" {...props}>
			<span>{text}</span>
		</Message>
	);
}
